<template>
  <div class="login-inner--wrap pa-4 pa-sm-8">
    <header class="w-100 d-flex justify-center align-center flex-column mb-8">
      <h1>
        <v-fade-transition mode="out-in">
          <span v-if="step === 'info'" key="info">{{ $t('patientPortal.login.title') }}</span>
          <span v-else-if="step === 'newPin'" key="newPin">{{
            $t('patientPortal.login.welcome')
          }}</span>
          <span v-else-if="step === 'existingPin'" key="existingPin">{{
            $t('patientPortal.login.welcomeBack')
          }}</span>
        </v-fade-transition>
      </h1>
      <h2 class="text-caption mt-n4 text-center">
        <v-fade-transition mode="out-in">
          <span v-if="step === 'info'" key="info">{{
            $t('patientPortal.login.infoSubtitle')
          }}</span>
          <span v-else-if="step === 'newPin'">{{ $t('patientPortal.login.newPinSubtitle') }}</span>
          <span v-else-if="step === 'existingPin'" key="existingPin">{{
            $t('patientPortal.login.existingPinSubtitle')
          }}</span>
        </v-fade-transition>
      </h2>
    </header>
    <main>
      <v-form class="login ma-auto" @submit.prevent="login">
        <div class="form-inner--wrap w-100">
          <div class="d-flex no-wrap justify-center">
            <v-slide-x-transition mode="out-in">
              <!-- HCN AND DOB INPUT -->
              <div v-if="step === 'info'" key="info" style="max-width: 100%" class="flex-shrink-0">
                <div class="d-flex nowrap">
                  <v-text-field
                    :label="$t('main.hcn')"
                    :name="$t('main.hcn')"
                    v-model="hcn"
                    outlined
                    x-large
                    class="mr-2"
                    prepend-inner-icon="mdi-card-bulleted-outline"
                    v-mask="hcMask"
                    @blur="$v.hcn.$touch()"
                    @change="$v.hcn.$touch()"
                    data-cy="loginform--hcn"
                  >
                    <template v-slot:append>
                      <v-slide-x-reverse-transition>
                        <div v-if="$v.hcn.$dirty">
                          <v-tooltip top :disabled="!$v.hcn.$invalid">
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                :color="$v.hcn.$invalid ? 'error' : 'success'"
                                v-on="on"
                                v-bind="attrs"
                                >{{
                                  $v.hcn.$invalid
                                    ? 'mdi-alert-circle-outline'
                                    : 'mdi-check-circle-outline'
                                }}</v-icon
                              >
                            </template>
                            <span>{{ $t('patientPortal.login.errors.invalidHcn') }}</span>
                          </v-tooltip>
                        </div>
                      </v-slide-x-reverse-transition>
                    </template>
                  </v-text-field>
                  <v-autocomplete
                    :items="provinceOptions"
                    hide-details
                    outlined
                    style="width: 30%"
                    append-icon=""
                    label="Province"
                    v-model="province"
                    data-cy="loginform--hcnprovince"
                  >
                    <template v-slot:selection="{ item }">
                      {{ $t(`provinces.abbrev.${item.value}`) }}
                    </template>
                  </v-autocomplete>
                </div>
                <date-picker
                  :label="$t('main.dob')"
                  :placeholder="$t('main.dob')"
                  :date="dob"
                  pickerColor="primary"
                  @updateDate="dob = $event"
                  :max="now"
                  :min="'1901-01-01'"
                  :errors="loginHcnError && [loginHcnError]"
                >
                  <template v-slot:append>
                    <v-slide-x-reverse-transition>
                      <v-icon color="success" v-if="dob">mdi-check-circle-outline</v-icon>
                    </v-slide-x-reverse-transition>
                  </template>
                </date-picker>
              </div>
              <!-- CREATE NEW PIN -->
              <div v-else-if="step === 'newPin'" key="newPin" class="flex-shrink-0 w-100">
                <div class="d-flex flex-column align-center">
                  <password-input
                    label="PIN"
                    name="PIN"
                    v-model="newPin"
                    outlined
                    class="mr-2"
                    style="max-width: 200px"
                    @blur="$v.newPin.$touch()"
                    @change="$v.newPin.$touch()"
                    :mask="PIN_VMASK"
                    data-cy="passwordinput--newpin"
                  >
                    <template v-slot:append>
                      <v-slide-x-reverse-transition>
                        <div v-if="$v.newPin.$dirty">
                          <v-tooltip top :disabled="!$v.newPin.$invalid">
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                :color="$v.newPin.$invalid ? 'error' : 'success'"
                                v-on="on"
                                v-bind="attrs"
                                >{{
                                  $v.newPin.$invalid
                                    ? 'mdi-alert-circle-outline'
                                    : 'mdi-check-circle-outline'
                                }}</v-icon
                              >
                            </template>
                            <span>{{
                              $t('patientPortal.login.errors.pinLength', { length: PIN_LENGTH })
                            }}</span>
                          </v-tooltip>
                        </div>
                      </v-slide-x-reverse-transition>
                    </template>
                  </password-input>
                  <password-input
                    :label="$t('patientPortal.login.confirmPin')"
                    :name="$t('patientPortal.login.confirmPin')"
                    v-model="confirmNewPin"
                    outlined
                    class="mr-2"
                    style="max-width: 200px"
                    @blur="$v.confirmNewPin.$touch()"
                    @change="$v.confirmNewPin.$touch()"
                    :mask="PIN_VMASK"
                    data-cy="passwordinput--confirmnewpin"
                  >
                    <template v-slot:append>
                      <v-slide-x-reverse-transition>
                        <div v-if="$v.confirmNewPin.$dirty">
                          <v-tooltip top :disabled="!$v.confirmNewPin.$invalid">
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                :color="$v.confirmNewPin.$invalid ? 'error' : 'success'"
                                v-on="on"
                                v-bind="attrs"
                                >{{
                                  $v.confirmNewPin.$invalid
                                    ? 'mdi-alert-circle-outline'
                                    : 'mdi-check-circle-outline'
                                }}</v-icon
                              >
                            </template>
                            <span>{{ $t('patientPortal.login.errors.pinMismatch') }}</span>
                          </v-tooltip>
                        </div>
                      </v-slide-x-reverse-transition>
                    </template>
                  </password-input>
                  <div class="px-2">
                    <v-checkbox hide-details v-model="privacy">
                      <template v-slot:label>
                        <div>
                          <span>{{ $t('patientPortal.login.privacyConsent') }}</span>
                          <a
                            href="https://www.sentryhealth.ca/privacy-policy"
                            target="_blank"
                            @click.stop
                          >
                            {{ $t('patientPortal.login.privacyPolicy').toLowerCase() }}</a
                          >
                        </div>
                      </template>
                    </v-checkbox>
                  </div>
                </div>
              </div>
              <div v-else-if="step === 'existingPin'" key="existingPin" class="flex-shrink-0 w-100">
                <div class="d-flex flex-column align-center">
                  <password-input
                    :error-messages="pinError ? [pinError] : null"
                    :label="$t('main.pin')"
                    :name="$t('main.pin')"
                    v-model="pin"
                    outlined
                    class="mr-2"
                    style="max-width: 200px"
                    @blur="$v.pin.$touch()"
                    @change="$v.pin.$touch()"
                    @input="
                      (e) => {
                        pin = e;
                        pinError = null;
                      }
                    "
                    :mask="PIN_VMASK"
                    data-cy="passwordinput--existingpin"
                  >
                    <template v-slot:append>
                      <v-slide-x-reverse-transition>
                        <div v-if="$v.pin.$dirty">
                          <v-tooltip top :disabled="!$v.pin.$invalid">
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                :color="$v.pin.$invalid ? 'error' : 'success'"
                                v-on="on"
                                v-bind="attrs"
                                >{{
                                  $v.pin.$invalid
                                    ? 'mdi-alert-circle-outline'
                                    : 'mdi-check-circle-outline'
                                }}</v-icon
                              >
                            </template>
                            <span>{{
                              $t('patientPortal.login.errors.pinLength', { length: PIN_LENGTH })
                            }}</span>
                          </v-tooltip>
                        </div>
                      </v-slide-x-reverse-transition>
                    </template>
                  </password-input>
                </div>
              </div>
            </v-slide-x-transition>
          </div>
          <!-- ENTER EXISTING PIN -->

          <v-spacer></v-spacer>
          <div v-if="step === 'info'">
            <v-btn
              color="primary"
              contained
              elevation="3"
              type="submit"
              block
              class="mt-4 mb-6"
              x-large
              :disabled="disabled"
              :loading="loading"
            >
              Login
            </v-btn>
          </div>
          <div v-else-if="step === 'newPin'">
            <v-btn
              color="primary"
              contained
              elevation="3"
              type="submit"
              block
              class="mt-4 mb-6"
              x-large
              :disabled="$v.newPin.$invalid || $v.confirmNewPin.$invalid || $v.privacy.$invalid"
              :loading="loading"
            >
              {{ $t('patientPortal.login.createPin') }}
            </v-btn>
          </div>
          <div v-else-if="step === 'existingPin'" class="d-flex flex-column align-center">
            <v-btn
              color="primary"
              contained
              elevation="3"
              type="submit"
              block
              class="mt-4 mb-6"
              x-large
              :disabled="$v.pin.$invalid"
              :loading="loading"
            >
              {{ $t('main.submit') }}
            </v-btn>
            <v-slide-x-transition mode="out-in">
              <a
                class="mt-n4 forgot-pin"
                v-if="!showForgotNumber"
                @click="showForgotNumber = true"
                key="forgotpin"
                >{{ $t('patientPortal.login.forgotPin') }}?</a
              >
              <a class="mt-n4 forgot-pin" v-else key="pinsupport"
                >{{ $t('patientPortal.login.resetPinInstruction') }}
                <span class="font-weight-bold">{{ $t('main.supportNumber') }}</span></a
              >
            </v-slide-x-transition>
          </div>
        </div>
      </v-form>
    </main>
  </div>
</template>
<style lang="scss" scoped>
.login-inner--wrap {
  background-color: white;
  box-shadow: $pretty-shadow;
  border-radius: 16px;
  width: min(100%, 500px);
  height: fit-content;
  h1 {
    margin-top: $spacer * 6;
    margin-bottom: $spacer * 6;
    font-size: 1.2em;
    text-align: center;
  }
  .form-inner--wrap {
    // overflow: hidden;
    ::v-deep .v-input {
      margin-top: $spacer * 2;
      .v-select__selections {
        flex-wrap: nowrap;
      }
    }
    .transition--wrap {
      display: flex;
      flex-wrap: nowrap;
      overflow: hidden;
      justify-content: center;
    }
    .forgot-pin {
      font-size: 0.8em;
    }
  }
}
</style>
<script>
import moment from 'moment';
import { required } from 'vuelidate/lib/validators';
import provinces from '../constants/provinces';
import { getAndSaveUser } from '../requests';
import { AUTH_TOKEN } from '../constants/storage';
import logo from '../images/logo-full.png';

import DatePicker from './DatePicker.vue';
import PasswordInput from './PasswordInput.vue';
import { validateHcFormat } from '../utils/healthcard';
import { DATE_FORMAT } from '../constants/moment';

export default {
  components: {
    DatePicker,
    PasswordInput,
  },
  data() {
    return {
      confirmNewPin: null,
      dob: null,
      hcn: null,
      loading: false,
      loginHcnError: null,
      logo,
      memberId: null,
      newPin: null,
      now: moment().format(DATE_FORMAT),
      PIN_LENGTH: 4,
      PIN_VMASK: '####',
      pin: null,
      pinError: null,
      privacy: false,
      province: 'ontario',
      showForgotNumber: false,
      step: 'info',
    };
  },
  methods: {
    async goToPortal(token) {
      await localStorage.setItem(AUTH_TOKEN, token);
      await getAndSaveUser();
      this.$nextTick(() => {
        this.$router.push({ name: 'Home' });
      });
    },
    async login() {
      this.loading = true;
      if (this.step === 'info') {
        const { error, hasPin } = await this.loginWithHcnAndDob();
        if (!error) {
          if (hasPin) {
            this.step = 'existingPin';
          } else {
            this.step = 'newPin';
          }
        }
      } else if (this.step === 'newPin') {
        const success = await this.setMemberPin();
        if (success) this.step = 'existingPin';
      } else if (this.step === 'existingPin') {
        const token = await this.loginWithPin();
        if (token) await this.goToPortal(token);
      }
      this.loading = false;
    },
    async loginWithHcnAndDob() {
      if (this.$route.params.tenantUrl) {
        await this.$store.dispatch('getProviderId', { tenantUrl: this.$route.params.tenantUrl });
      }
      const { data, errors } = await this.$apollo.query({
        query: await this.$store.getters.getQuery('MembersByHealthCardTwo'),
        variables: {
          providerId: this.$store.state.registration.providerId,
          healthCardNumber: this.hcn.replaceAll(' ', '').replaceAll('-', ''),
          accountForDob: true,
          dateOfBirth: this.$store.getters.toTimezone(this.dob).unix(),
          province: this.province,
        },
        fetchPolicy: 'no-cache',
      });
      const [user] =
        data?.SearchService?.searchUsers?.membersByHealthCard2?.membersByHealthCard2Results || [];

      if (!user || errors) {
        this.loginHcnError = this.$t('patientPortal.login.errors.noMember');
        return { error: true };
      }
      const { userId, hasPin } = user;
      this.memberId = userId;
      return { error: false, hasPin };
    },
    async loginWithPin() {
      try {
        const { data } = await this.$apollo.mutate({
          mutation: await this.$store.getters.getMutation('LoginMemberWithPin'),
          variables: {
            tenantUrl: this.$route.params?.tenantUrl,
            memberId: this.memberId,
            pin: this.pin,
          },
        });
        const { loginMemberWithPin: token } = data;
        return token;
      } catch {
        this.pinError = this.$t('patientPortal.login.errors.invalidPin');
        this.pin = null;
        return null;
      }
    },
    async setMemberPin() {
      const { errors } = await this.$apollo.mutate({
        mutation: await this.$store.getters.getMutation('SetMemberPin'),
        variables: {
          providerId: this.$store.state.registration.providerId,
          memberId: this.memberId,
          pin: this.newPin,
        },
      });
      return !errors;
    },
  },
  computed: {
    disabled() {
      return !this.dob || this.$v.hcn.$invalid || this.loading;
    },
    hcMask() {
      return provinces.find((p) => p.key === this.province).format;
    },
    provinceOptions() {
      return provinces.map((p) => {
        return {
          text: this.$t(`provinces.${p.key}`),
          value: p.key,
        };
      });
    },
    userInfo() {
      // TODO: Move to Store.getters
      return this.$store.state.patientPortal.user.info;
    },
    tenantUrl() {
      return this.$route.params.tenantUrl;
    },
  },
  mounted() {
    // Change the theme.
    this.$store.commit('setTheme', this.tenantUrl);
    if (this.userInfo.id) {
      this.$router.push({ name: 'Home' });
    }
  },
  validations() {
    return {
      hcn: {
        isValid: () => {
          return validateHcFormat(this.hcn, this.province);
        },
      },
      newPin: {
        required,
        checkLength: (val) => {
          return val?.length === this.PIN_LENGTH;
        },
      },
      confirmNewPin: {
        isTheSame: (val) => {
          return val === this.newPin;
        },
      },
      pin: {
        required,
        checkLength: (val) => {
          return val?.length === this.PIN_LENGTH;
        },
        noError: () => {
          return !this.pinError;
        },
      },
      privacy: {
        required: (val) => !!val,
      },
    };
  },
};
</script>
