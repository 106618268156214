<template>
  <div
    class="h-100 w-100 login-outer--wrap d-flex align-center"
    :class="{
      'flex-column justify-center bg--blue': $vuetify.breakpoint.smAndDown,
    }"
  >
    <!-- SPLASH left hand side -->

    <login-splash v-if="$vuetify.breakpoint.mdAndUp" />

    <!-- END splash -->

    <div
      class="h-100 login__container"
      :class="{
        'd-flex align-center flex-column justify-center': $vuetify.breakpoint.mdAndUp,
        'login--grid ': $vuetify.breakpoint.smAndDown,
      }"
    >
      <div
        class="my-8 my-sm-12 d-flex justify-center flex-column align-center"
        v-if="$vuetify.breakpoint.smAndDown"
      >
        <div @click="goSentry">
          <logo-image
            class="logo-image"
            :max-width="$vuetify.breakpoint.xsOnly ? '125px' : '150px'"
            height="unset"
            mode="dark"
          />
        </div>
        <h1
          class="white--text text-center mt-4"
          :style="{ fontSize: $vuetify.breakpoint.xs && '1.5em' }"
        >
          {{ $t('patientPortal.title') }}
        </h1>
        <h2
          class="white--text text-center"
          style="font-size: 1.2em; font-weight: normal"
          v-if="$vuetify.breakpoint.sm"
        >
          {{ $t('patientPortal.subtitle') }}
        </h2>
      </div>
      <div class="w-100 d-flex justify-center px-4 pb-4">
        <login-form />
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
@import '../../styles/utility.scss';
.login-outer--wrap {
  font-family: 'Montserrat', sans-serif !important;
  position: relative;
  height: calc(100 * var(--vh, 1vh));

  overflow: auto;
  .login__container {
    width: 50%;
  }

  .login--grid {
    display: grid;
    grid-template-columns: 1fr;
    min-height: calc(100 * var(--vh, 1vh));
    grid-template-rows: auto 1fr;
    width: 100%;

    height: 100%;
  }
  .login-footer__wrap {
    @include md {
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }
}
</style>
<script>
import { AUTH_TOKEN } from '../../constants/storage';
import { NODE_ENV, PROD } from '../../constants/env';
import LogoImage from '../../components/LogoImage.vue';
import LoginSplash from '../../components/LoginSplash.vue';
import LoginForm from '../../components/LoginForm.vue';

export default {
  name: 'Login',
  components: {
    LogoImage,
    LoginForm,
    LoginSplash,
  },
  data() {
    return {};
  },
  methods: {
    async checkLoggedIn() {
      const token = await localStorage.getItem(AUTH_TOKEN);
      if (token) {
        this.$router.push({ name: 'Home' });
      }
    },
    goSentry() {
      window.open('https://www.sentryhealth.ca', '_blank');
    },
    async setDefaultTenantUrl() {
      if (NODE_ENV === PROD) {
        await localStorage.setItem('tenantUrl', 'sentry-health');
      } else {
        await localStorage.setItem('tenantUrl', 'demo');
      }
    },
    async setTenantUrl() {
      if (this.$route.params?.tenantUrl) return;
      const tenantUrl = localStorage.getItem('tenantUrl');
      if (tenantUrl) {
        this.$router.push({ name: 'Login', params: { tenantUrl } });
      } else {
        await this.setDefaultTenantUrl();
        this.setTenantUrl();
      }
    },
  },
  async mounted() {
    this.$store.commit('setTheme', this.tenantUrl);
    await this.checkLoggedIn();
    this.setTenantUrl();
  },
};
</script>
