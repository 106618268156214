import { render, staticRenderFns } from "./LoginForm.vue?vue&type=template&id=a5a52f1c&scoped=true"
import script from "./LoginForm.vue?vue&type=script&lang=js"
export * from "./LoginForm.vue?vue&type=script&lang=js"
import style0 from "./LoginForm.vue?vue&type=style&index=0&id=a5a52f1c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a5a52f1c",
  null
  
)

export default component.exports