<template>
  <v-text-field
    v-model="password"
    v-mask="mask"
    v-bind="$attrs"
    :type="revealed ? 'text' : 'password'"
    :append-outer-icon="revealed ? 'mdi-eye-off-outline' : 'mdi-eye-outline'"
    @click:append-outer="revealed = !revealed"
    @blur="$emit('blur')"
    @change="$emit('change')"
    :autofocus="autofocus"
  >
    <!-- -->
    <template v-slot:append>
      <slot name="append" />
    </template>
  </v-text-field>
</template>
<script>
export default {
  name: 'PasswordInput',
  data() {
    return {
      revealed: false,
    };
  },
  props: {
    autofocus: Boolean,
    errors: Array,
    mask: String,
    value: String,
  },
  computed: {
    password: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
};
</script>
