<template>
  <div class="splash--container bg--blue h-100">
    <div @click="goSentry" style="cursor: pointer">
      <logo-image :max-width="'min(15vw, 250px)'" height="unset" mode="dark" prefix="logo" />
    </div>
    <div class="logo-splash--image d-flex justify-center" style="height: 50%">
      <login-splash-image />
    </div>
    <div class="d-flex flex-column align-center mb-10">
      <h1 class="white--text text-center">{{ $t('patientPortal.title') }}</h1>
      <h2 class="white--text text-center">{{ $t('patientPortal.subtitle') }}</h2>
    </div>
  </div>
</template>
<style lang="scss" scoped>
$splash-padding: $spacer * 10; // 80px
.splash--container {
  background-size: cover;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
  max-width: 50vw;
  width: 50%;
  padding: $splash-padding;
  h1 {
    font-size: calc(30vh * 0.2);
  }
  h2 {
    font-size: calc(30vh * 0.1);
  }
  p {
    font-size: calc(30vh * 0.07);
  }
}
</style>
<script>
import LoginSplashImage from './LoginSplashImage.vue';
import LogoImage from './LogoImage.vue';

export default {
  data() {
    return {};
  },
  components: {
    LoginSplashImage,
    LogoImage,
  },
  methods: {
    goSentry() {
      window.open('https://www.sentryhealth.ca', '_blank');
    },
  },
};
</script>
